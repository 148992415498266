import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import resetTransitionHomePage from "../modules/resetTransitionOnHomePage"
import Layout from "../components/layout"
import SEO from "../components/seo"

//import "../utils/scss/index.scss"
import Helmet from "react-helmet"

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  if (typeof window !== "undefined") {
    setTimeout(resetTransitionHomePage, 1100)
  }

  return (
    <Layout title={siteTitle}>
      <SEO title="About" keywords={[`blog`, `gatsby`, `javascript`, `react`]} />
      <Helmet>
        <body className="main about" id="body" />
      </Helmet>

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h1 className="big-font">About velvetzoo</h1>
          <figure className="kg-card kg-image-card kg-width-full">
            <Img
              fluid={data.aboutImage.childImageSharp.fluid}
              className="kg-image"
              alt="Greg Rosemblun"
              width="auto"
            />
            <figcaption style={{ fontSize: "14px" }}>
              Greg Rosemblun, creative and digital work at velvetzoo -
              photograph: Leo Calzoni
            </figcaption>
          </figure>
          <h2 id="dynamic-styles">Greg Rosemblun</h2>
          <p>
            Created in 2008, Velvetzoo is the creative Alias of Greg Rosemblun.
            <br />
            Designing and Coding are my passion. For over 15 years, I have been
            dedicated to contributing to the world of Graphic and Digital
            Design. I have extensive experience working as both a freelance
            designer as well as full-time staff, working across the spectrum
            from collaborating with passionate entrepreneurs, start-ups to being
            a vital member of the team at well-established firms.
            <br />
            My expertise in Graphic Design covers Branding, Identity Design,
            Illustration, Printing process.
            <br />
            Digital design experience includes UX/UI Design, web and visual
            design, and frontend development.
          </p>
          <p>
            <strong>Greg Rosemblun</strong>:{" "}
            <a
              href="https://www.linkedin.com/in/gregrosemblun/"
              rel="noopener noreferrer"
              target="_blank"
            >
              linkedin
            </a>{" "}
          </p>

          <h3 className={"big-font p-top-lg"} style={{ textAlign: "left" }}>
            Clients & Projects
          </h3>

          <div className="row">
            <div className={"col-6"}>
              <p>
                InOut Barcelona Tours
                <br />
                Visionaire
                <br />
                Creactivitat
                <br />
                Lemonblack
                <br />
                Antai
                <br />
                Nemuru
                <br />
                Deliberry
                <br />
                Vilma
                <br />
                Shoppiday
                <br />
                Taste it
                <br />
                TDM
                <br />
                Cook&Roll
                <br />
                Can Olle de la Guardia
                <br />
                Feel Free Tours
                <br />
                Bike Lovers Barcelona
                <br />
              </p>
            </div>

            <div className={"col-6"}>
              <p>
                Clean Meal
                <br />
                Negro Carbón
                <br />
                Mama Shake
                <br />
                Luxiot
                <br />
                Associació Catalana d'Universitats Públiques
                <br />
                Generalitat Valenciana
                <br />
                RedBull
                <br />
                Nestle
                <br />
                Habitat Inmobiliaria
                <br />
                Kwitcase
                <br />
                Gol a Gol
                <br />
                French tech Barcelona
                <br />
                Helper app
                <br />
                Flickle app
                <br />
                Yogaroom Barcelona
                <br />
              </p>
            </div>
          </div>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    aboutImage: file(relativePath: { eq: "greg-rosemblun.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)
